import React, { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../auth/msalConfig";
import { useNavigate } from "react-router-dom";

function Login() {
  const { instance, accounts, inProgress } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    if (accounts.length > 0) {
      navigate('/home');
    }
  }, [accounts, navigate]);

  const handleLogin = async () => {
    // Clear session storage
    // localStorage.clear();
    sessionStorage.clear();

    if (inProgress === "none") {
      try {
        await instance.loginRedirect(loginRequest);
      } catch (error) {
        console.error("Login failed:", error);
      }
    }
  };

  if (inProgress !== "none") {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Welcome to Echo
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Please sign in to continue
          </p>
        </div>
        <div>
          <button
            onClick={handleLogin}
            disabled={inProgress !== "none"}
            className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white 
              ${inProgress !== "none"
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              }`}
          >
            Sign in with Microsoft
          </button>
        </div>
      </div>
    </div>
  );
}

export default Login; 
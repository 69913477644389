import React, { useState, useEffect } from "react";
import { Tab } from "@headlessui/react";
import RecordView from './Records/RecordView';
import SelectHomePopover from './Records/SelectHomePopover';
import { apiClient, API_ENDPOINTS } from "../api/apiConfig";

function Records() {
  const [selectedType, setSelectedType] = useState(null);
  const [showHomePopover, setShowHomePopover] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  // Remove selectedChild state and showChildPopover
  const [selectedHome, setSelectedHome] = useState(null);

  // Get selectedChild from localStorage when needed
  const selectedChild = JSON.parse(localStorage.getItem('selectedChild'));

  // Remove handleChildSelect function

  // Categories state consolidated into objects
  const [childCategories, setChildCategories] = useState({
    loading: false,
    error: null,
    observation: null,
    others: [],
    selected: null
  });

  const [homeCategories, setHomeCategories] = useState({
    loading: false,
    error: null,
    observation: null,
    others: [],
    selected: null
  });

  const [personalCategories, setPersonalCategories] = useState({
    loading: false,
    observation: null,
    others: [],
    selected: null
  });

  // Add these missing state definitions
  const [selectedChildCategory, setSelectedChildCategory] = useState(null);
  const [selectedHomeCategory, setSelectedHomeCategory] = useState(null);
  const [selectedPersonalCategory, setSelectedPersonalCategory] = useState(null);

  // Update useEffect to remove child selection logic
  useEffect(() => {
    if (selectedChild && activeTab === 0) {
      setChildCategories(prev => ({ ...prev, loading: true, error: null }));
      fetchCategories();
    }
  }, [activeTab]);

  useEffect(() => {
    if (selectedHome) {
      fetchHomeCategories();
    }
  }, [selectedHome]);

  useEffect(() => {
    if (activeTab === 2) {
      fetchPersonalCategories();
    }
  }, [activeTab]);

  const fetchCategories = async () => {
    setChildCategories(prev => ({ ...prev, loading: true, error: null }));
    try {
      const response = await apiClient.get(API_ENDPOINTS.GET_CATEGORIES('child_note'));
      const categories = response.categories || [];

      const observation = categories.find(cat => cat.category_name.toLowerCase() === 'observation');
      const others = categories.filter(cat => cat.category_name.toLowerCase() !== 'observation');

      setChildCategories(prev => ({
        ...prev,
        loading: false,
        observation,
        others
      }));
    } catch (error) {
      console.error('Failed to fetch categories:', error);
      setChildCategories(prev => ({
        ...prev,
        loading: false,
        error: 'Failed to load categories'
      }));
    }
  };

  const fetchHomeCategories = async () => {
    setHomeCategories(prev => ({ ...prev, loading: true, error: null }));
    try {
      const response = await apiClient.get(API_ENDPOINTS.GET_CATEGORIES('home_note'));
      const categories = response.categories || [];

      const observation = categories.find(cat => cat.category_name.toLowerCase() === 'observation');
      const others = categories.filter(cat => cat.category_name.toLowerCase() !== 'observation');

      setHomeCategories(prev => ({
        ...prev,
        loading: false,
        observation,
        others
      }));
    } catch (error) {
      console.error('Failed to fetch home categories:', error);
      setHomeCategories(prev => ({
        ...prev,
        loading: false,
        error: 'Failed to load categories'
      }));
    }
  };

  const fetchPersonalCategories = async () => {
    setPersonalCategories(prev => ({ ...prev, loading: true }));
    try {
      const response = await apiClient.get(API_ENDPOINTS.GET_CATEGORIES('personal_note'));
      const categories = response.categories || [];

      const observation = categories.find(cat => cat.category_name.toLowerCase() === 'observation');
      const others = categories.filter(cat => cat.category_name.toLowerCase() !== 'observation');

      setPersonalCategories({
        loading: false,
        observation,
        others
      });
    } catch (error) {
      console.error('Failed to fetch personal categories:', error);
      setPersonalCategories(prev => ({ ...prev, loading: false }));
    }
  };

  const handleHomeSelect = (home) => {
    setSelectedHome(home);
    setShowHomePopover(false);
  };

  const formatCategoryName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handlePersonalCategorySelect = (category) => {
    setSelectedPersonalCategory(category);
    setSelectedType('personal');
  };

  const handleTabChange = (index) => {
    setActiveTab(index);

    // Reset all state except selectedChild
    setSelectedType(null);
    setSelectedHome(null);
    setSelectedChildCategory(null);
    setSelectedHomeCategory(null);
    setSelectedPersonalCategory(null);

    // Reset category states
    setChildCategories({
      loading: false,
      error: null,
      observation: null,
      others: [],
      selected: null
    });

    setHomeCategories({
      loading: false,
      error: null,
      observation: null,
      others: [],
      selected: null
    });

    setPersonalCategories({
      loading: false,
      observation: null,
      others: [],
      selected: null
    });

    // Only show home popover if on home tab
    if (index === 1) {
      setShowHomePopover(true);
    }

    // Hide home popover for personal notes tab
    if (index === 2) {
      setShowHomePopover(false);
    }
  };

  return (
    <div className="flex flex-col space-y-6">
      <Tab.Group selectedIndex={activeTab} onChange={handleTabChange}>
        <Tab.List className="flex space-x-1 rounded-lg bg-blue-900/20 p-1 mb-2">
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-md font-medium leading-5 text-blue-700
               ${selected
                ? "bg-white shadow"
                : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Child Notes
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-md font-medium leading-5 text-blue-700
               ${selected
                ? "bg-white shadow"
                : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Home Notes
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-md font-medium leading-5 text-blue-700
               ${selected
                ? "bg-white shadow"
                : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Personal Notes
          </Tab>
        </Tab.List>

        <Tab.Panels>
          <Tab.Panel>
            <div className="flex flex-col space-y-6">
              <h1 className="text-2xl font-bold">Record Child Note</h1>
              {selectedChild ? (
                <div className="space-y-4">
                  {selectedChildCategory ? (
                    <div className="space-y-4">
                      <div className="bg-primary-50 p-2 rounded-lg flex justify-between items-center text-sm">
                        <p className="text-primary-700 text-sm">
                          Selected Category: {formatCategoryName(selectedChildCategory.category_name)}
                        </p>
                        <button
                          onClick={() => setSelectedChildCategory(null)}
                          className="px-3 py-1 text-xs font-medium text-primary-600 hover:text-primary-700 bg-white rounded-md border border-primary-300 hover:bg-primary-50 transition-colors"
                        >
                          Switch Category
                        </button>
                      </div>

                      <div className="bg-white shadow rounded-lg p-6">
                        <RecordView
                          selectedCategory="child"
                          selectedChildCategory={selectedChildCategory}
                          selectedChildState={selectedChild}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      {childCategories.loading ? (
                        <div className="text-center py-4">
                          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-600 mx-auto"></div>
                          <p className="mt-2 text-sm text-gray-600">Loading categories...</p>
                        </div>
                      ) : childCategories.error ? (
                        <div className="text-red-600 text-center py-4">
                          <p>{childCategories.error}</p>
                          <button
                            onClick={fetchCategories}
                            className="mt-2 text-primary-600 hover:text-primary-700 font-medium"
                          >
                            Try again
                          </button>
                        </div>
                      ) : (
                        <div className="space-y-6">
                          {childCategories.observation && (
                            <div>
                              <h3 className="text-lg font-semibold mb-4">Record Quick Observation</h3>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                                <button
                                  onClick={() => setSelectedChildCategory(childCategories.observation)}
                                  className="p-4 bg-white rounded-xl hover:bg-gray-50 transition-all duration-200 text-left border border-gray-200 shadow-sm hover:shadow-md group max-w-sm"
                                >
                                  <h3 className="font-medium text-gray-900 group-hover:text-primary-600 transition-colors">
                                    {formatCategoryName(childCategories.observation.category_name)}
                                  </h3>
                                </button>
                              </div>
                            </div>
                          )}

                          {childCategories.others.length > 0 && (
                            <div>
                              <h3 className="text-lg font-semibold mb-4">Record For Other Categories</h3>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                                {childCategories.others.map((category) => (
                                  <button
                                    key={category.id}
                                    onClick={() => setSelectedChildCategory(category)}
                                    className="p-4 bg-white rounded-xl hover:bg-gray-50 transition-all duration-200 text-left border border-gray-200 shadow-sm hover:shadow-md group max-w-sm"
                                  >
                                    <h3 className="font-medium text-gray-900 group-hover:text-primary-600 transition-colors">
                                      {formatCategoryName(category.category_name)}
                                    </h3>
                                  </button>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-center py-8">
                  <p className="text-gray-600">Please select a child to start recording</p>
                </div>
              )}
            </div>
          </Tab.Panel>

          <Tab.Panel>
            <div className="flex flex-col space-y-6">
              <h1 className="text-2xl font-bold">Record Home Note</h1>
              {selectedHome ? (
                <div className="space-y-4">
                  <div className="bg-primary-50 p-2 rounded-lg flex justify-between items-center text-sm">
                    <p className="text-primary-700 text-sm">Selected Home: {selectedHome.name}</p>
                    <button
                      onClick={() => setShowHomePopover(true)}
                      className="px-3 py-1 text-xs font-medium text-primary-600 hover:text-primary-700 bg-white rounded-md border border-primary-300 hover:bg-primary-50 transition-colors"
                    >
                      Switch Home
                    </button>
                  </div>

                  {selectedHomeCategory ? (
                    <div className="space-y-4">
                      <div className="bg-primary-50 p-2 rounded-lg flex justify-between items-center text-sm">
                        <p className="text-primary-700 text-sm">
                          Selected Category: {formatCategoryName(selectedHomeCategory.category_name)}
                        </p>
                        <button
                          onClick={() => setSelectedHomeCategory(null)}
                          className="px-3 py-1 text-xs font-medium text-primary-600 hover:text-primary-700 bg-white rounded-md border border-primary-300 hover:bg-primary-50 transition-colors"
                        >
                          Switch Category
                        </button>
                      </div>

                      <div className="bg-white shadow rounded-lg p-6">
                        <RecordView
                          selectedCategory="home"
                          selectedHomeCategory={selectedHomeCategory}
                          selectedHomeState={selectedHome}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>
                      {homeCategories.loading ? (
                        <div className="text-center py-4">
                          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-600 mx-auto"></div>
                          <p className="mt-2 text-sm text-gray-600">Loading categories...</p>
                        </div>
                      ) : homeCategories.error ? (
                        <div className="text-red-600 text-center py-4">
                          <p>{homeCategories.error}</p>
                          <button
                            onClick={fetchHomeCategories}
                            className="mt-2 text-primary-600 hover:text-primary-700 font-medium"
                          >
                            Try again
                          </button>
                        </div>
                      ) : (
                        <div className="space-y-6">
                          {homeCategories.observation && (
                            <div>
                              <h3 className="text-lg font-semibold mb-4">Record Quick Observation</h3>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                                <button
                                  onClick={() => setSelectedHomeCategory(homeCategories.observation)}
                                  className="p-4 bg-white rounded-xl hover:bg-gray-50 transition-all duration-200 text-left border border-gray-200 shadow-sm hover:shadow-md group max-w-sm"
                                >
                                  <h3 className="font-medium text-gray-900 group-hover:text-primary-600 transition-colors">
                                    {formatCategoryName(homeCategories.observation.category_name)}
                                  </h3>
                                </button>
                              </div>
                            </div>
                          )}

                          {homeCategories.others.length > 0 && (
                            <div>
                              <h3 className="text-lg font-semibold mb-4">Record For Other Categories</h3>
                              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                                {homeCategories.others.map((category) => (
                                  <button
                                    key={category.id}
                                    onClick={() => setSelectedHomeCategory(category)}
                                    className="p-4 bg-white rounded-xl hover:bg-gray-50 transition-all duration-200 text-left border border-gray-200 shadow-sm hover:shadow-md group max-w-sm"
                                  >
                                    <h3 className="font-medium text-gray-900 group-hover:text-primary-600 transition-colors">
                                      {formatCategoryName(category.category_name)}
                                    </h3>
                                  </button>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              ) : !showHomePopover ? (
                <div className="text-center py-8">
                  <p className="text-gray-600">
                    <button
                      onClick={() => setShowHomePopover(true)}
                      className="text-primary-600 hover:text-primary-700 font-medium underline"
                    >
                      Click here
                    </button>
                    {' '}to select a home to start recording
                  </p>
                </div>
              ) : null}
            </div>
          </Tab.Panel>

          <Tab.Panel>
            <div className="flex flex-col space-y-6">
              <h1 className="text-2xl font-bold">Record Personal Note</h1>

              <div className="space-y-4">
                {selectedPersonalCategory ? (
                  <div className="space-y-4">
                    <div className="bg-primary-50 p-2 rounded-lg flex justify-between items-center text-sm">
                      <p className="text-primary-700 text-sm">
                        Selected Category: {formatCategoryName(selectedPersonalCategory.category_name)}
                      </p>
                      <button
                        onClick={() => {
                          setSelectedPersonalCategory(null);
                          setSelectedType(null);
                        }}
                        className="px-3 py-1 text-xs font-medium text-primary-600 hover:text-primary-700 bg-white rounded-md border border-primary-300 hover:bg-primary-50 transition-colors"
                      >
                        Switch Category
                      </button>
                    </div>

                    <div className="bg-white shadow rounded-lg p-6">
                      <RecordView
                        selectedCategory="personal"
                        selectedPersonalCategory={selectedPersonalCategory}
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    {personalCategories.loading ? (
                      <div className="text-center py-4">
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-600 mx-auto"></div>
                        <p className="mt-2 text-sm text-gray-600">Loading categories...</p>
                      </div>
                    ) : (
                      <div className="space-y-6">
                        {personalCategories.observation && (
                          <div>
                            <h3 className="text-lg font-semibold mb-4">Record Quick Observation</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                              <button
                                onClick={() => handlePersonalCategorySelect(personalCategories.observation)}
                                className="p-4 bg-white rounded-xl hover:bg-gray-50 transition-all duration-200 text-left border border-gray-200 shadow-sm hover:shadow-md group max-w-sm"
                              >
                                <h3 className="font-medium text-gray-900 group-hover:text-primary-600 transition-colors">
                                  {formatCategoryName(personalCategories.observation.category_name)}
                                </h3>
                              </button>
                            </div>
                          </div>
                        )}

                        {personalCategories.others.length > 0 && (
                          <div>
                            <h3 className="text-lg font-semibold mb-4">Record For Other Categories</h3>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                              {personalCategories.others.map((category) => (
                                <button
                                  key={category.id}
                                  onClick={() => handlePersonalCategorySelect(category)}
                                  className="p-4 bg-white rounded-xl hover:bg-gray-50 transition-all duration-200 text-left border border-gray-200 shadow-sm hover:shadow-md group max-w-sm"
                                >
                                  <h3 className="font-medium text-gray-900 group-hover:text-primary-600 transition-colors">
                                    {formatCategoryName(category.category_name)}
                                  </h3>
                                </button>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {showHomePopover && (
        <SelectHomePopover
          onClose={() => setShowHomePopover(false)}
          onSelectHome={handleHomeSelect}
        />
      )}
    </div>
  );
}

export default Records;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchNotes, syncPrompts } from "../store/slices/notesSlice";
import { fetchHighlights, regenerateHighlights } from './Highlights/fetchUtils';
import DailyTimeline from './Highlights/DailyTimeline';
import DailyMoodFluctuation from './Highlights/DailyMoodFluctuation';
import { Toaster } from 'react-hot-toast';
import { TOAST_OPTIONS, getFormattedDate } from '../utils/constants';

function Highlights() {
    const dispatch = useDispatch();
    const notes = useSelector((state) => state.notes.notes);
    // Update loading selector to be more specific
    const isNotesLoading = useSelector((state) => state.notes.isNotesLoading);
    const isPromptsLoading = useSelector((state) => state.notes.isPromptsLoading);
    // Use combined loading state
    const loading = isNotesLoading || isPromptsLoading;
    const [nameFilter, setNameFilter] = useState(() => {
        const savedChild = localStorage.getItem('selectedChild');
        return savedChild ? JSON.parse(savedChild).name : "";
    });
    const [selectedDate, setSelectedDate] = useState(() => {
        const today = new Date();
        today.setDate(today.getDate() - 1);
        return today.toISOString().split('T')[0];
    });
    const [highlights, setHighlights] = useState(null);
    const [isHighlightsLoading, setIsHighlightsLoading] = useState(false);
    const [expandedEvents, setExpandedEvents] = useState(new Set());
    const [isRegenerating, setIsRegenerating] = useState(false);
    const [apiError, setApiError] = useState(false);

    const savedChild = localStorage.getItem('selectedChild');
    const savedChildName = savedChild ? JSON.parse(savedChild).name : null;

    const uniqueNames = [...new Set([
        ...(savedChildName ? [savedChildName] : []),  // Include saved name first if it exists
        ...(notes?.records
            ?.filter(note => note?.record_type === "child_note")
            ?.map(note => note?.child_name)
            ?.filter(Boolean) || [])
    ])];

    useEffect(() => {
        const handleStorageChange = () => {
            const savedChild = localStorage.getItem('selectedChild');
            if (savedChild) {
                const child = JSON.parse(savedChild);
                setNameFilter(child.name);
                fetchFilteredHighlights(selectedDate, child.name);
            }
        };

        const initializeData = async () => {
            // Skip if there was a previous API error or if we're already loading
            if (apiError || loading) {
                return;
            }

            try {
                // First handle initial data loading
                if (!notes?.records) {
                    await Promise.all([
                        dispatch(syncPrompts()).unwrap(),
                        dispatch(fetchNotes()).unwrap()
                    ]);
                    return;
                }

                if (notes?.records?.length > 0) {
                    // Check localStorage first
                    const savedChild = localStorage.getItem('selectedChild');
                    if (savedChild) {
                        const child = JSON.parse(savedChild);
                        setNameFilter(child.name);
                        await fetchFilteredHighlights(selectedDate, child.name);
                    } else {
                        // Fall back to first name in the list if no saved child
                        const currentName = !nameFilter && uniqueNames.length > 0
                            ? uniqueNames[0]
                            : nameFilter;

                        if (!nameFilter && uniqueNames.length > 0) {
                            setNameFilter(uniqueNames[0]);
                        }

                        await fetchFilteredHighlights(selectedDate, currentName);
                    }
                }
            } catch (error) {
                console.error('Error in initializeData:', error);
                setApiError(true);
            }
        };

        // Listen for storage changes
        window.addEventListener('storage', handleStorageChange);

        // Run initial setup
        initializeData();

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [notes, dispatch, loading, selectedDate, apiError]); // Added apiError to dependencies

    const fetchFilteredHighlights = async (dateValue, nameValue) => {
        setIsHighlightsLoading(true);
        try {
            const formattedDate = getFormattedDate(dateValue);
            const highlightsData = await fetchHighlights(formattedDate, nameValue);
            setHighlights(highlightsData?.highlight);
            setApiError(false); // Reset error state on successful fetch
        } catch (error) {
            console.error('Failed to fetch highlights:', error);
            setHighlights(null);
            setApiError(true);
        } finally {
            setIsHighlightsLoading(false);
        }
    };

    const handleDateChange = async (e) => {
        const newDate = e.target.value;
        setSelectedDate(newDate);

    };

    const handleNameFilterChange = async (e) => {
        const newNameFilter = e.target.value;
        setNameFilter(newNameFilter);
        await fetchFilteredHighlights(selectedDate, newNameFilter);
    };

    const toggleEventExpansion = (index) => {
        setExpandedEvents(prev => {
            const newSet = new Set(prev);
            if (newSet.has(index)) {
                newSet.delete(index);
            } else {
                newSet.add(index);
            }
            return newSet;
        });
    };

    const handleRegenerate = async () => {
        setIsRegenerating(true);
        try {
            const formattedDate = getFormattedDate(selectedDate);
            await regenerateHighlights(formattedDate, nameFilter);
            await fetchFilteredHighlights(selectedDate, nameFilter);
        } catch (error) {
            console.error('Failed to regenerate highlights:', error);
        } finally {
            setIsRegenerating(false);
        }
    };

    return (
        <div className="space-y-6">
            <Toaster position="top-center" toastOptions={TOAST_OPTIONS} />
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                <h2 className="text-2xl font-bold text-gray-900">
                    Highlights
                </h2>
                {!loading && !isHighlightsLoading && notes?.records && notes.records.length > 0 && (
                    <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-4 w-full sm:w-auto">
                        <input
                            type="date"
                            value={selectedDate}
                            onChange={handleDateChange}
                            className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm w-full sm:w-auto"
                        />

                        <select
                            value={nameFilter}
                            onChange={handleNameFilterChange}
                            className="rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm w-full sm:w-auto"
                        >
                            {uniqueNames.map(name => (
                                <option key={name} value={name}>
                                    {name}
                                </option>
                            ))}
                        </select>

                        <button
                            onClick={handleRegenerate}
                            disabled={isRegenerating}
                            className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50"
                        >
                            {isRegenerating ? (
                                <>
                                    <div className="animate-spin mr-2 h-4 w-4 border-2 border-white border-t-transparent rounded-full"></div>
                                    Regenerating...
                                </>
                            ) : (
                                'Regenerate'
                            )}
                        </button>
                    </div>
                )}
            </div>

            {(loading || isHighlightsLoading) && (
                <div className="flex justify-center items-center py-12">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
                </div>
            )}

            {!loading && !isHighlightsLoading && (!highlights?.highlight_note ||
                Object.keys(highlights.highlight_note).length === 0 ||
                !highlights.highlight_note?.timelineEvents?.length) && (
                    <div className="text-center py-12 bg-white shadow overflow-hidden sm:rounded-md">
                        <p className="text-gray-500">Highlights not available</p>
                    </div>
                )}

            {!loading && !isHighlightsLoading && highlights?.highlight_note &&
                highlights.highlight_note?.timelineEvents?.length > 0 && (
                    <>
                        <DailyMoodFluctuation
                            events={highlights.highlight_note?.timelineEvents}

                        />
                        <DailyTimeline
                            events={highlights.highlight_note?.timelineEvents}
                            expandedEvents={expandedEvents}
                            toggleEventExpansion={toggleEventExpansion}
                        />
                    </>
                )}
        </div>
    );
}

export default Highlights;

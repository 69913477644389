import { apiClient, API_ENDPOINTS } from '../../api/apiConfig';
import { useState } from 'react';

export const useObservationSummary = () => {
    const [observationSummaries, setObservationSummaries] = useState([]);
    const [loadingObservations, setLoadingObservations] = useState(false);
    const [copiedObservations, setCopiedObservations] = useState(false);

    const fetchObservationSummaryHandler = async () => {
        setLoadingObservations(true);
        try {
            const response = await apiClient.get(API_ENDPOINTS.GET_OBSERVATIONS_SUMMARY);
            const sortedSummaries = response?.observation_summary?.sort((a, b) =>
                new Date(b.observation_date) - new Date(a.observation_date)
            ) || [];
            setObservationSummaries(sortedSummaries);
        } catch (error) {
            console.error('Error fetching observation summary:', error);
            setObservationSummaries([]);
        } finally {
            setLoadingObservations(false);
        }
    };

    const handleCopyObservation = (summaryId) => {
        const summary = observationSummaries.find(s => s.id === summaryId);
        if (summary?.observation_summary) {
            navigator.clipboard.writeText(summary.observation_summary);
            setCopiedObservations(true);
            setTimeout(() => setCopiedObservations(false), 2000);
        }
    };

    return {
        observationSummaries,
        loadingObservations,
        copiedObservations,
        fetchObservationSummaryHandler,
        handleCopyObservation
    };
};

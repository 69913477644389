import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchNotes } from "../../store/slices/notesSlice";
import {
    ClipboardDocumentIcon,
    PencilIcon,
    XMarkIcon,
    CheckIcon,
    ArrowPathIcon
} from "@heroicons/react/24/outline";
import AudioPlayer from '../NotesView/AudioPlayer';
import { formatDateTime } from '../../utils/constants';
import ExpandIcon from '../NotesView/ExpandIcon';
import {
    handleUpdateSummary,
    handleRegenerateSummary
} from '../NotesView/handleUtils';

function GeneratedOutput({ recordId }) {
    const dispatch = useDispatch();
    const notes = useSelector((state) => state.notes.notes);
    const loading = useSelector((state) => state.notes.isNotesLoading);
    const [noteDetails, setNoteDetails] = useState(null);
    const [copiedTranscript, setCopiedTranscript] = useState(false);
    const [copiedAdditionalNotes, setCopiedAdditionalNotes] = useState(false);
    const [copiedSummary, setCopiedSummary] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [editingSummary, setEditingSummary] = useState(false);
    const [editedSummaryText, setEditedSummaryText] = useState("");
    const [updatingSummaryId, setUpdatingSummaryId] = useState(null);
    const [regeneratingSummaryId, setRegeneratingSummaryId] = useState(null);

    useEffect(() => {
        dispatch(fetchNotes());
    }, [dispatch]);

    useEffect(() => {
        if (notes?.records && recordId) {
            const note = notes.records.find(record => record.id === recordId);
            if (note) {
                setNoteDetails(note);
            }
        }
    }, [notes, recordId]);

    const handleCopy = (text, setCopiedState) => {
        navigator.clipboard.writeText(text);
        setCopiedState(true);
        setTimeout(() => setCopiedState(false), 2000);
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const handleUpdateSummaryWrapper = async () => {
        await handleUpdateSummary({
            recordId,
            editedSummaryText,
            setUpdatingSummaryId,
            setEditingSummary,
            setEditedSummaryText,
            setFilteredNotes: () => {
                setNoteDetails(prev => ({
                    ...prev,
                    summary: editedSummaryText
                }));
            }
        });
    };

    const handleRegenerateSummaryWrapper = async () => {
        try {
            const newSummary = await handleRegenerateSummary({
                recordId,
                setRegeneratingSummaryId,
                setNoteDetails,
                setFilteredNotes: () => { },
                notes
            });

            setNoteDetails(prev => ({
                ...prev,
                summary: newSummary
            }));
        } catch (error) {
            console.error("Failed to regenerate summary:", error);
        }
    };

    if (!recordId || loading) {
        return (
            <div className="flex justify-center items-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
            </div>
        );
    }

    if (!noteDetails) {
        return null;
    }

    return (
        <div className="mt-8 space-y-6">
            <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold text-gray-900">Generated Output</h2>
                <button
                    onClick={toggleExpand}
                    className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                >
                    <ExpandIcon isExpanded={isExpanded} />
                </button>
            </div>

            <div className="space-y-4">
                {/* AI Note Assistant Section - Always visible */}
                <div className="bg-white rounded-lg p-4 shadow-sm">
                    <div className="flex justify-between items-center mb-3">
                        <h4 className="text-sm font-medium text-gray-900">
                            AI Note Assistant
                        </h4>
                        <div className="flex items-center space-x-2">
                            {editingSummary ? (
                                <>
                                    <button
                                        onClick={handleUpdateSummaryWrapper}
                                        className="inline-flex items-center text-sm text-green-600 hover:text-green-700 px-2 py-1 rounded-md hover:bg-green-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                    >
                                        {updatingSummaryId === recordId ? (
                                            <>
                                                <svg
                                                    className="animate-spin h-4 w-4 mr-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                    />
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    />
                                                </svg>
                                                Saving...
                                            </>
                                        ) : (
                                            <>
                                                <CheckIcon className="h-4 w-4 mr-1" />
                                                Save
                                            </>
                                        )}
                                    </button>
                                    <button
                                        onClick={() => {
                                            setEditingSummary(false);
                                            setEditedSummaryText("");
                                        }}
                                        className="inline-flex items-center text-sm text-red-600 hover:text-red-700 px-2 py-1 rounded-md hover:bg-red-50"
                                    >
                                        <XMarkIcon className="h-4 w-4 mr-1" />
                                        Cancel
                                    </button>
                                </>
                            ) : (
                                <>
                                    {!noteDetails.summary && (
                                        <button
                                            onClick={handleRegenerateSummaryWrapper}
                                            disabled={regeneratingSummaryId === recordId}
                                            className="inline-flex items-center text-sm text-primary-600 hover:text-primary-700 px-2 py-1 rounded-md hover:bg-primary-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                        >
                                            {regeneratingSummaryId === recordId ? (
                                                <>
                                                    <svg
                                                        className="animate-spin h-4 w-4 mr-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                    >
                                                        <circle
                                                            className="opacity-25"
                                                            cx="12"
                                                            cy="12"
                                                            r="10"
                                                            stroke="currentColor"
                                                            strokeWidth="4"
                                                        />
                                                        <path
                                                            className="opacity-75"
                                                            fill="currentColor"
                                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        />
                                                    </svg>
                                                    Regenerating...
                                                </>
                                            ) : (
                                                <>
                                                    <ArrowPathIcon className="h-4 w-4 mr-1" />
                                                    Regenerate
                                                </>
                                            )}
                                        </button>
                                    )}
                                    <button
                                        onClick={() => {
                                            setEditingSummary(true);
                                            setEditedSummaryText(noteDetails.summary || "");
                                        }}
                                        className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                    >
                                        <PencilIcon className="h-4 w-4 mr-1" />
                                        Edit
                                    </button>
                                    {noteDetails.summary && (
                                        <button
                                            onClick={() => handleCopy(noteDetails.summary, setCopiedSummary)}
                                            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                        >
                                            <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                            {copiedSummary ? "Copied" : "Copy"}
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className="bg-primary-50 rounded-md p-4">
                        {editingSummary ? (
                            <textarea
                                value={editedSummaryText}
                                onChange={(e) => setEditedSummaryText(e.target.value)}
                                className="w-full min-h-[100px] p-2 text-sm text-gray-600 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                                placeholder="Enter summary..."
                            />
                        ) : (
                            <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                {noteDetails.summary || "AI Note Assistant not available"}
                            </p>
                        )}
                    </div>
                </div>

                {/* Expandable sections */}
                {isExpanded && (
                    <>
                        {/* Audio Section */}
                        <div className="bg-white rounded-lg p-4 shadow-sm">
                            <h4 className="text-sm font-medium text-gray-900 mb-3">
                                Audio Recording
                            </h4>
                            <AudioPlayer
                                recordId={recordId}
                                record_type_format={noteDetails.record_type_format}
                            />
                        </div>

                        {/* Transcript Section */}
                        <div className="bg-white rounded-lg p-4 shadow-sm">
                            <div className="flex justify-between items-center mb-3">
                                <h4 className="text-sm font-medium text-gray-900">
                                    Transcript
                                </h4>
                                {noteDetails.transcript && (
                                    <button
                                        onClick={() => handleCopy(noteDetails.transcript, setCopiedTranscript)}
                                        className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                    >
                                        <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                        {copiedTranscript ? "Copied" : "Copy"}
                                    </button>
                                )}
                            </div>
                            <div className="bg-gray-50 rounded-md p-4">
                                <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                    {noteDetails.transcript || "Transcript not available"}
                                </p>
                            </div>
                        </div>

                        {/* Additional Notes Section - Only show if not text format */}
                        {noteDetails.record_type_format !== 'text' && (
                            <div className="bg-white rounded-lg p-4 shadow-sm">
                                <div className="flex justify-between items-center mb-3">
                                    <h4 className="text-sm font-medium text-gray-900">
                                        Additional Notes
                                    </h4>
                                    {noteDetails.additional_notes && (
                                        <button
                                            onClick={() => handleCopy(noteDetails.additional_notes, setCopiedAdditionalNotes)}
                                            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                        >
                                            <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                            {copiedAdditionalNotes ? "Copied" : "Copy"}
                                        </button>
                                    )}
                                </div>
                                <div className="bg-gray-50 rounded-md p-4">
                                    <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                        {noteDetails.additional_notes || "No additional notes"}
                                    </p>
                                </div>
                            </div>
                        )}

                        {/* Metadata */}
                        <div className="bg-white rounded-lg p-4 shadow-sm">
                            <div>
                                <p className="text-sm font-medium text-gray-500">Created Date</p>
                                <p className="text-sm text-gray-900">
                                    {formatDateTime(noteDetails.created_at)}
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default GeneratedOutput;

import React, { useState, useEffect } from "react";
import { FunnelIcon } from "@heroicons/react/24/outline";

// Initial filter state without localStorage dependency
export const INITIAL_FILTERS = {
    dateRange: "today",
    recordType: "child",
    category: "all",
    entityName: "none",
};

// New hook to handle localStorage
export const useInitialFilters = () => {
    const [filters, setFilters] = useState(INITIAL_FILTERS);

    useEffect(() => {
        const savedChild = localStorage.getItem('selectedChild');
        if (savedChild) {
            const defaultEntityName = JSON.parse(savedChild).name;
            setFilters(prev => ({
                ...prev,
                entityName: defaultEntityName
            }));
        }
    }, []);

    return [filters, setFilters];
};

// Date range options for the filter dropdown
export const DATE_RANGE_OPTIONS = [
    { value: "all", label: "All Time" },
    { value: "today", label: "Today" },
    { value: "yesterday", label: "Yesterday" },
    { value: "lastWeek", label: "Last Week" },
    { value: "lastMonth", label: "Last Month" },
];

// Record type options for the filter dropdown
export const RECORD_TYPE_OPTIONS = [
    { value: "personal", label: "Personal Notes" },
    { value: "child", label: "Child Notes" },
    { value: "home", label: "Home Notes" },
];

// Get date range based on filter selection
export const getDateRange = (filters) => {
    const now = new Date();
    switch (filters.dateRange) {
        case "today":
            return {
                start: new Date(now.setHours(0, 0, 0, 0)),
                end: new Date(now.setHours(23, 59, 59, 999)),
            };
        case "yesterday":
            const yesterday = new Date(now);
            yesterday.setDate(yesterday.getDate() - 1);
            return {
                start: new Date(yesterday.setHours(0, 0, 0, 0)),
                end: new Date(yesterday.setHours(23, 59, 59, 999)),
            };
        case "lastWeek":
            const lastWeek = new Date(now);
            lastWeek.setDate(lastWeek.getDate() - 7);
            return {
                start: lastWeek,
                end: now,
            };
        case "lastMonth":
            const lastMonth = new Date(now);
            lastMonth.setMonth(lastMonth.getMonth() - 1);
            return {
                start: lastMonth,
                end: now,
            };
        default:
            return null;
    }
};

// Get category options based on record type
export const getCategoryOptions = (availableCategories, recordType) => {
    const relevantCategories = availableCategories[recordType] || [];

    if (relevantCategories.length > 1) {
        return [
            { value: "all", label: "All Categories" },
            ...relevantCategories.map((category) => ({
                value: category,
                label: category
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" "),
            })),
        ];
    }

    if (relevantCategories.length === 1) {
        const category = relevantCategories[0];
        return [{
            value: category,
            label: category
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
        }];
    }

    return [{ value: "none", label: "No Categories Available" }];
};

// Get entity name options based on record type
export const getEntityNameOptions = (notes, recordType) => {
    if (recordType === "home") {
        const uniqueHomeNames = [...new Set(
            notes.filter((note) => note.home_name).map((note) => note.home_name)
        )];
        return uniqueHomeNames.length === 0
            ? [{ value: "none", label: "Not Available" }]
            : uniqueHomeNames.map((name) => ({ value: name, label: name }));
    }

    if (recordType === "child") {
        // Get child from localStorage first
        const savedChild = localStorage.getItem('selectedChild');
        const savedChildName = savedChild ? JSON.parse(savedChild).name : null;

        // Get unique child names from notes
        const uniqueChildNames = [...new Set(
            notes.filter((note) => note.child_name).map((note) => note.child_name)
        )];

        // Create final options array
        let options = [];

        // Add localStorage name first if it exists
        if (savedChildName) {
            options.push({ value: savedChildName, label: savedChildName });
        }

        // Add remaining unique names from notes (excluding the localStorage name to avoid duplicates)
        uniqueChildNames
            .filter(name => name !== savedChildName)
            .forEach(name => options.push({ value: name, label: name }));

        return options.length === 0
            ? [{ value: "none", label: "Not Available" }]
            : options;
    }

    return [];
};

// Apply filters to notes
export const filterNotes = (notes, filters) => {
    if (!notes?.records) return [];

    let filtered = [...notes.records];

    // Filter by record type
    if (filters?.recordType) {
        const recordTypeMap = {
            personal: "personal_note",
            child: "child_note",
            home: "home_note",
        };
        const recordTypeToFilter = recordTypeMap[filters.recordType];
        filtered = filtered.filter(
            (note) => note?.record_type === recordTypeToFilter
        );
    }

    // Filter by category
    if (filters?.category && filters.category !== 'all') {
        filtered = filtered.filter(
            (note) => note?.category?.toLowerCase() === filters.category.toLowerCase()
        );
    }

    // Filter by entity name
    if (filters?.entityName && filters.entityName !== 'none') {
        if (filters.recordType === 'home') {
            filtered = filtered.filter(
                (note) => note?.home_name === filters.entityName
            );
        } else if (filters.recordType === 'child') {
            filtered = filtered.filter(
                (note) => note?.child_name === filters.entityName
            );
        }
    }

    // Filter by date range
    if (filters?.dateRange && filters.dateRange !== 'all') {
        const dateRange = getDateRange(filters);
        if (dateRange) {
            filtered = filtered.filter((note) => {
                const noteDate = new Date(note.shift_date);
                return noteDate >= dateRange.start && noteDate <= dateRange.end;
            });
        }
    }

    return filtered;
};

// Apply filters to summaries
export const filterSummaries = (summarizedNotes, filters) => {
    if (!summarizedNotes) return [];

    let filtered = [...summarizedNotes];

    // Filter by record type
    if (filters.recordType) {
        const recordTypeMap = {
            personal: "personal_note",
            child: "child_note",
            home: "home_note",
        };
        const recordTypeToFilter = recordTypeMap[filters.recordType];
        filtered = filtered.filter((summary) =>
            summary.records.some(
                (record) => record.record_type === recordTypeToFilter
            )
        );
    }

    // Filter by date range
    if (filters.dateRange !== "all") {
        const dateRange = getDateRange(filters);
        if (dateRange) {
            filtered = filtered.filter((summary) => {
                const summaryDate = new Date(summary.created_at);
                return summaryDate >= dateRange.start && summaryDate <= dateRange.end;
            });
        }
    }

    // Filter by category
    if (filters.category !== "all") {
        filtered = filtered.filter((summary) =>
            summary.records.some(
                (record) =>
                    record.category?.toLowerCase() === filters.category.toLowerCase()
            )
        );
    }

    return filtered;
};

// FilterPanel Component
export const FilterPanel = ({
    filters,
    onFilterChange,
    availableCategories = [],
    notes = [],
}) => {
    const [isOpen, setIsOpen] = useState(true);

    const categoryOptions = getCategoryOptions(availableCategories, filters.recordType);
    const entityNameOptions = getEntityNameOptions(notes, filters.recordType);

    const handleFilterChange = (key, value) => {
        const newFilters = { ...filters };

        if (key === "recordType") {
            newFilters.recordType = value;
            newFilters.entityName = "none";
            const relevantCategories = availableCategories[value] || [];
            newFilters.category = relevantCategories.length === 1
                ? relevantCategories[0]
                : (relevantCategories.length > 1 ? 'all' : 'none');
        } else {
            newFilters[key] = value;
        }

        onFilterChange(newFilters);
    };

    const clearFilters = () => {
        onFilterChange(INITIAL_FILTERS);
    };

    return (
        <div className="mb-4">
            <div className="flex items-center justify-between">
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                    <FunnelIcon
                        className={`h-4 w-4 mr-2 ${isOpen ? "text-primary-600" : "text-gray-500"}`}
                    />
                    Filters {isOpen ? "(Hide)" : "(Show)"}
                </button>
                {isOpen && (
                    <button
                        onClick={clearFilters}
                        className="text-sm text-gray-600 hover:text-gray-900"
                    >
                        Clear all
                    </button>
                )}
            </div>

            {isOpen && (
                <div className="mt-4 p-4 bg-white rounded-lg shadow border">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {/* Date Range Filter */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 font-serif">
                                Date Range
                            </label>
                            <select
                                value={filters.dateRange}
                                onChange={(e) => handleFilterChange("dateRange", e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm py-2 px-3"
                            >
                                {DATE_RANGE_OPTIONS.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Record Type Filter */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 font-serif">
                                Record Type
                            </label>
                            <select
                                value={filters.recordType}
                                onChange={(e) => handleFilterChange("recordType", e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm py-2 px-3"
                            >
                                {RECORD_TYPE_OPTIONS.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Entity Name Filter */}
                        {(filters.recordType === "home" || filters.recordType === "child") && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700 mb-1 font-serif">
                                    {filters.recordType === "home" ? "Home Name" : "Child Name"}
                                </label>
                                <select
                                    value={filters.entityName}
                                    onChange={(e) => handleFilterChange("entityName", e.target.value)}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm py-2 px-3"
                                >
                                    {entityNameOptions.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        {/* Category Filter */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1 font-serif">
                                Category
                            </label>
                            <select
                                value={filters.category}
                                onChange={(e) => handleFilterChange("category", e.target.value)}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm py-2 px-3"
                            >
                                {categoryOptions.map((option) => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}; 
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./auth/msalConfig";
import { store } from "./store/store";
import Layout from "./components/Layout";
import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider } from "./auth/AuthContext";
import { initHotjar } from "./utils/hotjar";
import HighlightsPage from './pages/HighlightsPage';
import RecordsPage from './pages/RecordsPage';
import NotesViewPage from './pages/NotesViewPage';
import Settings from "./pages/SettingsPage";


function App() {
  useEffect(() => {
    initHotjar();
  }, []);

  return (
    <MsalProvider instance={msalInstance}>
      <AuthProvider>
        <Provider store={store}>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/home"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<HighlightsPage />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/notes"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<NotesViewPage />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/record-page"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<RecordsPage />} />
                    </Layout>
                  </>
                }
              />
              <Route
                path="/settings"
                element={
                  <>
                    <Layout>
                      <ProtectedRoute element={<Settings />} />
                    </Layout>
                  </>
                }
              />
            </Routes>
          </Router>
        </Provider>
      </AuthProvider>
    </MsalProvider>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { apiClient, API_ENDPOINTS } from "../../api/apiConfig";

const SelectHomePopover = ({ onClose, onSelectHome }) => {
    const [homes, setHomes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [newHomeName, setNewHomeName] = useState("");
    const [selectedHome, setSelectedHome] = useState(null);

    useEffect(() => {
        fetchHomes();
    }, []);

    const fetchHomes = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await apiClient.get(API_ENDPOINTS.GET_HOMES);
            setHomes(response.homes || []);
        } catch (err) {
            setError("Failed to load homes");
            console.error("Error fetching homes:", err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateHome = async (e) => {
        e.preventDefault();
        if (!newHomeName.trim()) return;

        try {
            const queryParams = new URLSearchParams({
                home_name: newHomeName,
            }).toString();

            await apiClient.post(`${API_ENDPOINTS.CREATE_HOME}?${queryParams}`);
            setNewHomeName("");
            setShowCreateModal(false);
            fetchHomes();
        } catch (error) {
            console.error("Failed to create home:", error);
        }
    };

    const handleHomeSelect = (home) => {
        setSelectedHome(home);
        onSelectHome({
            id: home.id,
            name: home.home_name,
        });
    };

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-2xl shadow-xl w-full max-w-md transform transition-all">
                <div className="flex justify-between items-center p-6 border-b border-gray-100">
                    <h3 className="text-xl font-semibold text-gray-800">Select Home to Start Recording</h3>
                    <button
                        onClick={onClose}
                        className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                    >
                        <XMarkIcon className="h-5 w-5 text-gray-500" />
                    </button>
                </div>

                {isLoading ? (
                    <div className="text-center py-12">
                        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary-600 mx-auto"></div>
                        <p className="mt-4 text-sm text-gray-600">Loading homes...</p>
                    </div>
                ) : error ? (
                    <div className="text-red-600 text-center py-12">
                        <p className="text-base">{error}</p>
                        <button
                            onClick={fetchHomes}
                            className="mt-4 text-primary-600 hover:text-primary-700 font-medium"
                        >
                            Try again
                        </button>
                    </div>
                ) : (
                    <div className="max-h-[400px] overflow-y-auto px-2">
                        {homes.length === 0 ? (
                            <div className="text-center py-12">
                                <p className="text-gray-600">No homes found.</p>
                                <p className="text-sm text-gray-500 mt-2">Add a home to get started.</p>
                            </div>
                        ) : (
                            <div className="py-2">
                                {homes.map((home) => (
                                    <button
                                        key={home.id}
                                        onClick={() => handleHomeSelect(home)}
                                        className={`w-full px-6 py-4 text-left rounded-xl mb-2 transition-all
                                            ${selectedHome?.id === home.id
                                                ? "bg-primary-50 text-primary-700 font-medium"
                                                : "text-gray-700 hover:bg-gray-50"
                                            }`}
                                    >
                                        {home.home_name}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {!showCreateModal ? (
                    <div className="p-6 border-t border-gray-100">
                        <button
                            onClick={() => setShowCreateModal(true)}
                            className="w-full flex items-center justify-center space-x-2 p-3 rounded-xl
                                text-primary-600 hover:bg-primary-50 transition-colors font-medium"
                        >
                            <PlusIcon className="h-5 w-5" />
                            <span>Add Home</span>
                        </button>
                    </div>
                ) : (
                    <div className="p-6 border-t border-gray-100">
                        <form onSubmit={handleCreateHome}>
                            <input
                                type="text"
                                value={newHomeName}
                                onChange={(e) => setNewHomeName(e.target.value)}
                                placeholder="Enter home's name"
                                className="w-full p-3 border border-gray-200 rounded-xl mb-3 focus:ring-2 
                                    focus:ring-primary-500 focus:border-transparent outline-none"
                                autoFocus
                            />
                            <div className="flex space-x-3">
                                <button
                                    type="button"
                                    onClick={() => setShowCreateModal(false)}
                                    className="flex-1 p-3 text-gray-700 hover:bg-gray-50 rounded-xl 
                                        transition-colors font-medium"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="flex-1 p-3 text-white bg-primary-600 hover:bg-primary-700 
                                        rounded-xl transition-colors font-medium"
                                >
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectHomePopover; 
import React, { useState, useEffect } from "react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { apiClient, API_ENDPOINTS } from "../../api/apiConfig";

const SelectChildPopover = ({ onClose, onSelectChild }) => {
    const [children, setChildren] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [newChildName, setNewChildName] = useState("");
    const [selectedChild, setSelectedChild] = useState(null);
    const [selectedColor, setSelectedColor] = useState("#FF5733");

    useEffect(() => {
        fetchChildren();
    }, []);

    const fetchChildren = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await apiClient.get(API_ENDPOINTS.GET_CHILDREN);
            setChildren(response.children || []);
        } catch (err) {
            setError("Failed to load children");
            console.error("Error fetching children:", err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCreateChild = async (e) => {
        e.preventDefault();
        if (!newChildName.trim()) return;

        try {
            const queryParams = new URLSearchParams({
                child_name: newChildName,
                colour: selectedColor.replace("#", ""),
            }).toString();

            await apiClient.post(`${API_ENDPOINTS.CREATE_CHILD}?${queryParams}`);
            setNewChildName("");
            setSelectedColor("#FF5733");
            setShowCreateModal(false);
            fetchChildren();
        } catch (error) {
            console.error("Failed to create child:", error);
        }
    };

    const handleChildSelect = (child) => {
        setSelectedChild(child);
        onSelectChild({
            id: child.id,
            name: child.child_name,
            colour: child.colour
        });
    };

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-2xl shadow-xl w-full max-w-md transform transition-all">
                <div className="flex justify-between items-center p-6 border-b border-gray-100">
                    <h3 className="text-xl font-semibold text-gray-800">Please select a child to continue</h3>
                    <button
                        onClick={onClose}
                        className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                    >
                        <XMarkIcon className="h-5 w-5 text-gray-500" />
                    </button>
                </div>

                {isLoading ? (
                    <div className="text-center py-12">
                        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary-600 mx-auto"></div>
                        <p className="mt-4 text-sm text-gray-600">Loading children...</p>
                    </div>
                ) : error ? (
                    <div className="text-red-600 text-center py-12">
                        <p className="text-base">{error}</p>
                        <button
                            onClick={fetchChildren}
                            className="mt-4 text-primary-600 hover:text-primary-700 font-medium"
                        >
                            Try again
                        </button>
                    </div>
                ) : (
                    <div className="max-h-[400px] overflow-y-auto px-2">
                        {children.length === 0 ? (
                            <div className="text-center py-12">
                                <p className="text-gray-600">No children found.</p>
                                <p className="text-sm text-gray-500 mt-2">Add a child to get started.</p>
                            </div>
                        ) : (
                            <div className="py-2">
                                {children.map((child) => (
                                    <button
                                        key={child.id}
                                        onClick={() => handleChildSelect(child)}
                                        className={`w-full px-6 py-4 text-left rounded-xl mb-2 transition-all flex items-center
                                            ${selectedChild?.id === child.id
                                                ? "bg-primary-50 text-primary-700 font-medium"
                                                : "hover:opacity-90"
                                            }`}
                                        style={{
                                            backgroundColor: child.colour ? `#${child.colour}` : '#FF5733',
                                            color: '#FFFFFF'
                                        }}
                                    >
                                        {child.child_name}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                {!showCreateModal ? (
                    <div className="p-6 border-t border-gray-100">
                        <button
                            onClick={() => setShowCreateModal(true)}
                            className="w-full flex items-center justify-center space-x-2 p-3 rounded-xl
                text-primary-600 hover:bg-primary-50 transition-colors font-medium"
                        >
                            <PlusIcon className="h-5 w-5" />
                            <span>Add Child</span>
                        </button>
                    </div>
                ) : (
                    <div className="p-6 border-t border-gray-100">
                        <form onSubmit={handleCreateChild}>
                            <div className="flex items-center space-x-3 mb-3">
                                <label className="text-gray-700 whitespace-nowrap">
                                    Child Name:
                                </label>
                                <input
                                    type="text"
                                    value={newChildName}
                                    onChange={(e) => setNewChildName(e.target.value)}
                                    placeholder="Enter child's name"
                                    className="w-full p-3 border border-gray-200 rounded-xl focus:ring-2 
                  focus:ring-primary-500 focus:border-transparent outline-none"
                                    autoFocus
                                />
                            </div>
                            <div className="flex items-center space-x-3 mb-3">
                                <label htmlFor="childColor" className="text-gray-700">
                                    Select Colour:
                                </label>
                                <input
                                    type="color"
                                    id="childColor"
                                    value={selectedColor}
                                    onChange={(e) => setSelectedColor(e.target.value)}
                                    className="h-8 w-16 rounded cursor-pointer"
                                />
                            </div>
                            <div className="flex space-x-3">
                                <button
                                    type="button"
                                    onClick={() => setShowCreateModal(false)}
                                    className="flex-1 p-3 text-gray-700 hover:bg-gray-50 rounded-xl 
                    transition-colors font-medium"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="flex-1 p-3 text-white bg-primary-600 hover:bg-primary-700 
                    rounded-xl transition-colors font-medium"
                                >
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectChildPopover;

import React, { useState, useEffect, useMemo } from 'react';
import { ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline';
import { SettingsSection } from '../../components/Settings';
import { apiClient, API_ENDPOINTS } from '../../api/apiConfig';

const BackendPromptsSection = () => {
    const [prompts, setPrompts] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPrompt, setCurrentPrompt] = useState({
        prompt_name: '',
        system_prompt: '',
        user_prompt: ''
    });
    const [filters, setFilters] = useState({
        record_type: '',
        category: ''
    });

    // Add new state for tracking which prompt is being edited
    const [editingPromptId, setEditingPromptId] = useState(null);
    const [editedPrompt, setEditedPrompt] = useState(null);

    // Record types and categories with their display names
    const recordTypeOptions = [
        { value: 'personal_note', label: 'Personal Note' },
        { value: 'home_note', label: 'Home Note' },
        { value: 'child_note', label: 'Child Note' }
    ];

    // Add state for storing categories from API
    const [categoriesByRecordType, setCategoriesByRecordType] = useState({});

    // Create memoized categoryOptions based on selected record type
    const categoryOptions = useMemo(() => {
        if (!filters.record_type || !categoriesByRecordType[filters.record_type]) {
            return [];
        }

        return categoriesByRecordType[filters.record_type].map(category => ({
            value: category.name.toLowerCase().replace(/\s+/g, '_'),
            label: category.name
        }));
    }, [filters.record_type, categoriesByRecordType]);

    // Helper function to get display name
    const getDisplayName = (value, options) => {
        const option = options.find(opt => opt.value === value);
        return option ? option.label : value;
    };

    const [errorMessage, setErrorMessage] = useState(null);

    const fetchPrompts = async () => {
        setIsLoading(true);
        setErrorMessage(null); // Reset error message on new fetch
        try {
            const queryParams = new URLSearchParams();
            queryParams.append('record_type', filters.record_type);
            queryParams.append('category', filters.category);

            const response = await apiClient.get(
                `${API_ENDPOINTS.GET_BACKEND_PROMPTS}?${queryParams.toString()}`
            );
            setPrompts(response.prompts || []);
        } catch (error) {
            console.error('Error fetching prompts:', error);
            if (error) {
                setErrorMessage('No prompts found for the selected filters');
                setPrompts([]);
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Add new state for submit loading
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Update the handleSubmit function
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const promptData = {
                ...currentPrompt,
                record_type_name: filters.record_type,
                category_name: filters.category
            };

            await apiClient.post(API_ENDPOINTS.CREATE_BACKEND_PROMPTS, promptData);
            setIsEditing(false);
            setCurrentPrompt({
                prompt_name: '',
                system_prompt: '',
                user_prompt: ''
            });
            await fetchPrompts();
        } catch (error) {
            console.error('Error saving prompt:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Add new state for tracking copied state
    const [copiedPromptId, setCopiedPromptId] = useState(null);

    // Update the copy function
    const handleCopyPrompt = (prompt) => {
        const promptText = prompt.prompt.map(p =>
            `Role: ${p.role}\nContent: ${p.content}`
        ).join('\n\n');
        navigator.clipboard.writeText(promptText);

        // Set the copied state for this prompt
        setCopiedPromptId(prompt.name);

        // Clear the copied state after 2 seconds
        setTimeout(() => {
            setCopiedPromptId(null);
        }, 2000);
    };

    // Add edit handlers
    const handleEditClick = (prompt) => {
        setEditingPromptId(prompt.name);
        setEditedPrompt({
            prompt_name: prompt.name,
            system_prompt: prompt.prompt.find(p => p.role === 'system')?.content || '',
            user_prompt: prompt.prompt.find(p => p.role === 'user')?.content || ''
        });
    };

    const handleCancelEdit = () => {
        setEditingPromptId(null);
        setEditedPrompt(null);
    };

    // Add new state for edit submit loading
    const [isEditSubmitting, setIsEditSubmitting] = useState(false);

    // Update handleSaveEdit function
    const handleSaveEdit = async (promptName) => {
        setIsEditSubmitting(true);
        try {
            const promptData = {
                ...editedPrompt,
            };
            await apiClient.put(API_ENDPOINTS.UPDATE_BACKEND_PROMPTS, promptData);
            setEditingPromptId(null);
            setEditedPrompt(null);
            await fetchPrompts();
        } catch (error) {
            console.error('Error saving prompt:', error);
        } finally {
            setIsEditSubmitting(false);
        }
    };

    // Add new state for category loading
    const [isCategoryLoading, setIsCategoryLoading] = useState(false);

    // Update the fetchCategories function
    const fetchCategories = async (recordType) => {
        setIsCategoryLoading(true);
        try {
            const response = await apiClient.get(API_ENDPOINTS.GET_CATEGORIES(recordType));
            if (!response.categories || response.categories.length === 0) {
                setCategoriesByRecordType(prev => ({
                    ...prev,
                    [recordType]: []
                }));
            } else {
                setCategoriesByRecordType(prev => ({
                    ...prev,
                    [recordType]: response.categories.map(cat => ({
                        name: cat.category_name
                            .split('_')
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(' '),
                        id: cat.id
                    }))
                }));
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            setCategoriesByRecordType(prev => ({
                ...prev,
                [recordType]: []
            }));
        } finally {
            setIsCategoryLoading(false);
        }
    };

    // Update record type change handler
    const handleRecordTypeChange = async (e) => {
        const newRecordType = e.target.value;
        setFilters({
            ...filters,
            record_type: newRecordType,
            category: '' // Reset category when record type changes
        });
        setPrompts([]); // Clear prompts
        setErrorMessage(null); // Clear error message
        setIsEditing(false); // Exit editing mode if active

        // Always fetch categories when record type changes
        if (newRecordType) {
            await fetchCategories(newRecordType);
        }
    };

    return (
        <SettingsSection title="Manage Backend Prompts" icon={ChatBubbleLeftRightIcon}>
            <div className="space-y-6">
                {/* Filters with Get Prompts button */}
                <div className="space-y-4">
                    <div className="flex space-x-4">
                        <div className="w-1/2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Record Type
                            </label>
                            <select
                                value={filters.record_type}
                                onChange={handleRecordTypeChange}
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                            >
                                <option value="">Please Select Record Type</option>
                                {recordTypeOptions.map(type => (
                                    <option key={type.value} value={type.value}>
                                        {type.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="w-1/2">
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Category
                            </label>
                            <div className="relative">
                                <select
                                    value={filters.category}
                                    onChange={(e) => {
                                        setFilters({
                                            ...filters,
                                            category: e.target.value
                                        });
                                        setPrompts([]); // Clear prompts
                                        setErrorMessage(null); // Clear error message
                                        setIsEditing(false); // Exit editing mode if active
                                    }}
                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                    disabled={!filters.record_type || isCategoryLoading}
                                >
                                    <option value="">
                                        {isCategoryLoading
                                            ? 'Loading categories...'
                                            : filters.record_type && categoriesByRecordType[filters.record_type]?.length === 0
                                                ? 'Category Not Available'
                                                : 'Please Select Category'
                                        }
                                    </option>
                                    {!isCategoryLoading && categoryOptions.map(category => (
                                        <option key={category.value} value={category.value}>
                                            {category.label}
                                        </option>
                                    ))}
                                </select>
                                {isCategoryLoading && (
                                    <div className="absolute right-8 top-1/2 -translate-y-1/2">
                                        <svg
                                            className="animate-spin h-5 w-5 text-gray-400"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth="4"
                                            />
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            />
                                        </svg>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Get Prompts Button with loading state */}
                    <div className="flex justify-end">
                        <button
                            onClick={fetchPrompts}
                            disabled={!filters.record_type || !filters.category || isLoading}
                            className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm
                                ${filters.record_type && filters.category && !isLoading
                                    ? 'text-white bg-primary-600 hover:bg-primary-700'
                                    : 'text-gray-400 bg-gray-100 cursor-not-allowed'
                                }`}
                        >
                            {isLoading ? (
                                <>
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Loading...
                                </>
                            ) : (
                                'Get Backend Prompts'
                            )}
                        </button>
                    </div>
                </div>

                {/* Error Message and Create New Prompt Button */}
                {errorMessage && (
                    <div className="text-center space-y-4">
                        <div className="text-gray-500">{errorMessage}</div>
                        {!isEditing && (
                            <button
                                onClick={() => setIsEditing(true)}
                                className="inline-flex items-center px-6 py-3 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 transition-colors duration-200"
                                disabled={!filters.record_type || !filters.category}
                            >
                                Create New Prompt
                            </button>
                        )}
                    </div>
                )}

                {/* Prompts List */}
                {!errorMessage && (
                    <div className="grid grid-cols-1 gap-4 mt-4">
                        {prompts.map((prompt) => (
                            <div
                                key={prompt.name}
                                className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm"
                            >
                                <div className="space-y-3">
                                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                                        <div className="w-full sm:w-auto">
                                            <span className="text-xs font-medium text-gray-500 block mb-1">Prompt Name:</span>
                                            <h3 className="font-medium text-gray-900 break-words">
                                                {prompt.name}
                                            </h3>
                                        </div>
                                        <div className="flex flex-wrap gap-2">
                                            <button
                                                onClick={() => handleCopyPrompt(prompt)}
                                                className="text-sm px-3 py-1 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 whitespace-nowrap"
                                            >
                                                {copiedPromptId === prompt.name ? 'Copied!' : 'Copy'}
                                            </button>
                                            {!editingPromptId && (
                                                <button
                                                    onClick={() => handleEditClick(prompt)}
                                                    className="text-sm px-3 py-1 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 whitespace-nowrap"
                                                >
                                                    Edit
                                                </button>
                                            )}
                                        </div>
                                    </div>

                                    <div className="flex space-x-2 mb-2">
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                            {getDisplayName(filters.record_type, recordTypeOptions)}
                                        </span>
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                            {getDisplayName(filters.category, categoryOptions)}
                                        </span>
                                    </div>

                                    {editingPromptId === prompt.name ? (
                                        <>
                                            <div className="space-y-4">
                                                <div className="relative p-5 rounded-lg bg-blue-50 border-l-4 border-blue-400">
                                                    <div className="flex items-center mb-3">
                                                        <div className="text-sm font-semibold text-blue-700">
                                                            System Prompt
                                                        </div>
                                                    </div>
                                                    <textarea
                                                        value={editedPrompt.system_prompt}
                                                        onChange={(e) => setEditedPrompt({
                                                            ...editedPrompt,
                                                            system_prompt: e.target.value
                                                        })}
                                                        rows={8}
                                                        className="w-full bg-white rounded-md px-4 py-3 
                                                            border border-blue-200 focus:border-blue-400 focus:ring-2 focus:ring-blue-100
                                                            shadow-sm transition-all duration-200 ease-in-out
                                                            text-gray-700 placeholder-gray-400
                                                            focus:outline-none"
                                                        placeholder="Enter system prompt here..."
                                                    />
                                                </div>
                                                <div className="relative p-5 rounded-lg bg-green-50 border-l-4 border-green-400">
                                                    <div className="flex items-center mb-3">
                                                        <div className="text-sm font-semibold text-green-700">
                                                            User Prompt
                                                        </div>
                                                    </div>
                                                    <textarea
                                                        value={editedPrompt.user_prompt}
                                                        onChange={(e) => setEditedPrompt({
                                                            ...editedPrompt,
                                                            user_prompt: e.target.value
                                                        })}
                                                        rows={3}
                                                        className="w-full bg-white rounded-md px-4 py-3 
                                                            border border-green-200 focus:border-green-400 focus:ring-2 focus:ring-green-100
                                                            shadow-sm transition-all duration-200 ease-in-out
                                                            text-gray-700 placeholder-gray-400
                                                            focus:outline-none"
                                                        placeholder="Enter user prompt here..."
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex justify-end space-x-3 mt-4">
                                                <button
                                                    onClick={() => handleSaveEdit(prompt.name)}
                                                    disabled={isEditSubmitting}
                                                    className="text-sm px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:bg-primary-400 disabled:cursor-not-allowed flex items-center"
                                                >
                                                    {isEditSubmitting ? (
                                                        <>
                                                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            Submitting...
                                                        </>
                                                    ) : (
                                                        'Submit'
                                                    )}
                                                </button>
                                                <button
                                                    onClick={handleCancelEdit}
                                                    className="text-sm px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {prompt.prompt.map((p, index) => (
                                                <div key={index} className="bg-gray-50 p-3 rounded border border-gray-200">
                                                    <div className="flex items-center text-sm text-gray-500 mb-2">
                                                        <span className="font-medium mr-2">Role:</span>
                                                        {p.role}
                                                    </div>
                                                    <div className="text-sm text-gray-700 whitespace-pre-wrap">
                                                        {p.content}
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {/* Form section with updated UI */}
                {isEditing && (
                    <div className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm">
                        <form onSubmit={handleSubmit} className="space-y-4">
                            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                                <div className="w-full">
                                    <span className="text-xs font-medium text-gray-500 block mb-1">
                                        Prompt Name:
                                    </span>
                                    <input
                                        type="text"
                                        value={currentPrompt.prompt_name}
                                        onChange={(e) => setCurrentPrompt({
                                            ...currentPrompt,
                                            prompt_name: e.target.value
                                        })}
                                        placeholder="Enter Prompt Name here..."
                                        className="mt-1 block w-full px-4 py-3 rounded-md 
                                            bg-gray-50 border-2 border-gray-200 
                                            focus:border-primary-500 focus:ring-2 focus:ring-primary-100 
                                            shadow-sm transition-all duration-200 ease-in-out
                                            text-gray-700 placeholder-gray-400 
                                            focus:bg-white focus:outline-none"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="flex flex-wrap gap-2 mb-2">
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                                    {getDisplayName(filters.record_type, recordTypeOptions)}
                                </span>
                                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                                    {getDisplayName(filters.category, categoryOptions)}
                                </span>
                            </div>

                            <div className="space-y-4">
                                <div className="relative p-5 rounded-lg bg-blue-50 border-l-4 border-blue-400">
                                    <div className="flex items-center mb-3">
                                        <div className="text-sm font-semibold text-blue-700">
                                            System Prompt
                                        </div>
                                    </div>
                                    <textarea
                                        value={currentPrompt.system_prompt}
                                        onChange={(e) => setCurrentPrompt({
                                            ...currentPrompt,
                                            system_prompt: e.target.value
                                        })}
                                        rows={8}
                                        className="w-full bg-white rounded-md px-4 py-3 
                                            border border-blue-200 focus:border-blue-400 focus:ring-2 focus:ring-blue-100
                                            shadow-sm transition-all duration-200 ease-in-out
                                            text-gray-700 placeholder-gray-400
                                            focus:outline-none"
                                        placeholder="Enter system prompt here..."
                                        required
                                    />
                                </div>
                                <div className="relative p-5 rounded-lg bg-green-50 border-l-4 border-green-400">
                                    <div className="flex items-center mb-3">
                                        <div className="text-sm font-semibold text-green-700">
                                            User Prompt
                                        </div>
                                    </div>
                                    <textarea
                                        value={currentPrompt.user_prompt}
                                        onChange={(e) => setCurrentPrompt({
                                            ...currentPrompt,
                                            user_prompt: e.target.value
                                        })}
                                        rows={3}
                                        className="w-full bg-white rounded-md px-4 py-3 
                                            border border-green-200 focus:border-green-400 focus:ring-2 focus:ring-green-100
                                            shadow-sm transition-all duration-200 ease-in-out
                                            text-gray-700 placeholder-gray-400
                                            focus:outline-none"
                                        placeholder="Enter user prompt here..."
                                        required
                                    />
                                </div>
                            </div>
                            <div className="flex justify-end space-x-3 mt-4">
                                <button
                                    type="submit"
                                    disabled={isSubmitting}
                                    className="text-sm px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:bg-primary-400 disabled:cursor-not-allowed flex items-center"
                                >
                                    {isSubmitting ? (
                                        <>
                                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Submitting...
                                        </>
                                    ) : (
                                        'Submit'
                                    )}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => setIsEditing(false)}
                                    className="text-sm px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </SettingsSection>
    );
};

export default BackendPromptsSection; 